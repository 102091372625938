<template>
	<div class="edit">
		<van-nav-bar
			title="个人信息"
			left-arrow
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="content">
			<h3 class="qjc-fts-32 qjc-texta-l qjc-ftw-b qjc-c-dark">问卷内容</h3>
			
			<div class="item">
				<van-field
					v-model="formData.height"
					label="身高"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">cm</span>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.weight"
					label="体重"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">kg</span>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.fat"
					label="体脂率"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">%</span>
				</van-field>
			</div>
			
			<div class="item" v-if="formData.chest">
				<van-field
					v-model="formData.chest"
					label="胸围"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">cm</span>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.waist"
					label="腰围"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">cm</span>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.hip"
					label="臀围"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">cm</span>
				</van-field>
				
			</div>
			
			<div class="item">
				<van-field
					v-model="areaText"
					label="居住地"
					readonly
					right-icon="arrow-down"
					input-align="right"
					@click="areaShow = true"
				>
				</van-field>
				
				<van-action-sheet 
					v-model="areaShow" 
					:actions="areaList" 
					cancel-text="取消"
					:close-on-click-action="true"
					@select="areaCheck"
				/>
				
			</div>
			
			<!-- <div class="item">
				<van-field
					v-model="marryText"
					label="婚姻状况"
					readonly
					right-icon="arrow-down"
					input-align="right"
					@click="marryShow = true"
				>
				</van-field>
				
				<van-action-sheet 
					v-model="marryShow" 
					:actions="marryList" 
					cancel-text="取消"
					:close-on-click-action="true"
					@select="marryCheck"
				/>
				
			</div> -->
			
			<!-- <div class="item">
				<van-field
					v-model="childcntText"
					label="子女数目"
					readonly
					right-icon="arrow-down"
					input-align="right"
					@click="childcntShow = true"
				>
				</van-field>
				
				<van-action-sheet 
					v-model="childcntShow" 
					:actions="childcntList" 
					cancel-text="取消"
					:close-on-click-action="true"
					@select="childcntCheck"
				/>
				
			</div> -->
			
			<div class="item">
				<van-field
					v-model="drinkText"
					label="是否喝酒"
					readonly
					right-icon="arrow-down"
					input-align="right"
					@click="drinkShow = true"
				>
				</van-field>
				
				<van-action-sheet 
					v-model="drinkShow" 
					:actions="drinkList" 
					cancel-text="取消"
					:close-on-click-action="true"
					@select="drinkCheck"
				/>
				
			</div>
			
			<div class="item">
				<van-field
					v-model="smokeText"
					label="是否吸烟"
					readonly
					right-icon="arrow-down"
					input-align="right"
					@click="smokeShow = true"
				>
				</van-field>
				
				<van-action-sheet 
					v-model="smokeShow" 
					:actions="smokeList" 
					cancel-text="取消"
					:close-on-click-action="true"
					@select="smokeCheck"
				/>
				
			</div>
			
			<div class="item">
				<van-field
					v-model="habeatText"
					label="用餐习惯"
					readonly
					right-icon="arrow-down"
					input-align="right"
					@click="habeatShow = true"
				>
				</van-field>
				
				<van-action-sheet 
					v-model="habeatShow" 
					:actions="habeatList" 
					cancel-text="取消"
					:close-on-click-action="true"
					@select="habeatCheck"
				/>
				
			</div>
			
			<div class="item">
				<van-field
					v-model="vegeText"
					label="全日素食者"
					readonly
					right-icon="arrow-down"
					input-align="right"
					@click="vegeShow = true"
				>
				</van-field>
				
				<van-action-sheet 
					v-model="vegeShow" 
					:actions="vegeList" 
					cancel-text="取消"
					:close-on-click-action="true"
					@select="vegeCheck"
				/>
				
			</div>
			
			<div class="item">
				<van-field
					v-model="habsportText"
					label="运动习惯"
					readonly
					right-icon="arrow-down"
					input-align="right"
					@click="habsportShow = true"
				>
				</van-field>
				
				<van-action-sheet 
					v-model="habsportShow" 
					:actions="habsportList" 
					cancel-text="取消"
					:close-on-click-action="true"
					@select="habsportCheck"
				/>
				
			</div>
			
			<div class="item">
				<van-field
					v-model="sleepText"
					label="睡眠障碍"
					readonly
					right-icon="arrow-down"
					input-align="right"
					@click="sleepShow = true"
				>
				</van-field>
				
				<van-action-sheet 
					v-model="sleepShow" 
					:actions="sleepList" 
					cancel-text="取消"
					:close-on-click-action="true"
					@select="sleepCheck"
				/>
				
			</div>
			<div class="item">
				<div class="van-field">
					<p class="van-field__label">是否患有以下疾病</p>
				</div>
				
				<van-radio-group
					v-model="hasDis"
					icon-size="0.28rem"
					class="has"
					checked-color="#6681FA"
					@change="checkdis"
				>
					<van-radio :name="true">是</van-radio>
					<van-radio :name="false">否</van-radio>
				</van-radio-group>
				
				<van-radio-group 
					v-for="dis in formData.disease" 
					:key="dis.kdis" 
					v-model="dis.level"
					icon-size="0.28rem"
					class="selec"
					checked-color="#6681FA"
					:disabled="!hasDis"
				>
					<label>{{ diseaseList.filter(i => i.kdis == dis.kdis )[0].name }}</label>
					<van-radio name="3">不服药</van-radio>
					<van-radio name="2">偶尔服药</van-radio>
					<van-radio name="1">经常服药</van-radio>
				</van-radio-group>
				
			</div>
			
			<div class="item">
				<div class="van-field">
					<p class="van-field__label">是否做过下列手术</p>
				</div>
				
				<van-radio-group
					v-model="hasOper"
					icon-size="0.28rem"
					class="has"
					checked-color="#6681FA"
					@change="checkOper"
				>
					<van-radio :name="true">是</van-radio>
					<van-radio :name="false">否</van-radio>
				</van-radio-group>
				
				<van-checkbox-group 
					v-model="formData.oper"
					icon-size="0.28rem"
					class="selec"
					checked-color="#6681FA"
					:disabled="!hasOper"
				>
					<van-checkbox name="1">头或脑部</van-checkbox>
					<van-checkbox name="2">眼鼻耳</van-checkbox>
					<van-checkbox name="3">口腔、食道</van-checkbox>
					<van-checkbox name="4">胸部(心、肺等)</van-checkbox>
					<van-checkbox name="5">腹部(胃、肝、胆、胰、肾等)</van-checkbox>
					<van-checkbox name="6">关节与骨</van-checkbox>
					<van-checkbox name="7">妇科手术</van-checkbox>
					<van-checkbox name="8">泌尿系统</van-checkbox>
				</van-checkbox-group>
				
			</div>
			
			<div class="item">
				<div class="van-field">
					<p class="van-field__label">您的直系亲属是否有下列疾病</p>
				</div>
				
				<van-radio-group
					v-model="hasFamdis"
					icon-size="0.28rem"
					class="has"
					checked-color="#6681FA"
					@change="checkFamdis"
				>
					<van-radio :name="true">是</van-radio>
					<van-radio :name="false">否</van-radio>
				</van-radio-group>
				
				<van-checkbox-group 
					v-model="formData.famdis"
					icon-size="0.28rem"
					class="selec"
					checked-color="#6681FA"
					:disabled="!hasFamdis"
				>
					<van-checkbox name="1">癌症</van-checkbox>
					<van-checkbox name="2">脑中风</van-checkbox>
					<van-checkbox name="3">心肌梗塞</van-checkbox>
					<van-checkbox name="4">肝硬化</van-checkbox>
					<van-checkbox name="5">老年失智</van-checkbox>
					<van-checkbox name="6">慢性阻塞性肺炎</van-checkbox>
					<van-checkbox name="7">结核病</van-checkbox>
					<van-checkbox name="8">先天畸形</van-checkbox>
				</van-checkbox-group>
				
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.sbp"
					label="收缩压"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">mmHg</span>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.dbp"
					label="舒张压"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">mmHg</span>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[0].ve"
					label="白细胞(WBC)"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">10³/μl</span>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[1].ve"
					label="血红蛋白(HGB)"
					type="number"
					input-align="right"
				>
					<van-radio-group
						v-model="formData.examine[1].ut"
						slot="right-icon"
						icon-size="0.28rem"
						checked-color="#6681FA"
						class="unit"
					>
						<van-radio name="A">g/dL</van-radio>
						<van-radio name="B">g/L</van-radio>
					</van-radio-group>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[2].ve"
					label="血小板(PLT)"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">10³/μl</span>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[3].ve"
					label="空腹血糖(FG)"
					type="number"
					input-align="right"
				>
					<van-radio-group
						v-model="formData.examine[3].ut"
						slot="right-icon"
						icon-size="0.28rem"
						checked-color="#6681FA"
						class="unit"
					>
						<van-radio name="A">mg/dL</van-radio>
						<van-radio name="B">mmol/mol</van-radio>
					</van-radio-group>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[4].ve"
					label="糖化血红蛋白(HbA1c)"
					type="number"
					input-align="right"
				>
					<van-radio-group
						v-model="formData.examine[4].ut"
						slot="right-icon"
						icon-size="0.28rem"
						checked-color="#6681FA"
						class="unit"
					>
						<van-radio name="A">%</van-radio>
						<van-radio name="B">mmol/mol</van-radio>
					</van-radio-group>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[5].ve"
					label="总胆固醇(CHOL)"
					type="number"
					input-align="right"
				>
					<van-radio-group
						v-model="formData.examine[5].ut"
						slot="right-icon"
						icon-size="0.28rem"
						checked-color="#6681FA"
						class="unit"
					>
						<van-radio name="A">mg/dL</van-radio>
						<van-radio name="B">mmol/L</van-radio>
					</van-radio-group>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[6].ve"
					label="低密度脂蛋白(LDLC)"
					type="number"
					input-align="right"
				>
					<van-radio-group
						v-model="formData.examine[6].ut"
						slot="right-icon"
						icon-size="0.28rem"
						checked-color="#6681FA"
						class="unit"
					>
						<van-radio name="A">mg/dL</van-radio>
						<van-radio name="B">mmol/L</van-radio>
					</van-radio-group>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[7].ve"
					label="高密度脂蛋白(HDLC)"
					type="number"
					input-align="right"
				>
					<van-radio-group
						v-model="formData.examine[7].ut"
						slot="right-icon"
						icon-size="0.28rem"
						checked-color="#6681FA"
						class="unit"
					>
						<van-radio name="A">mg/dL</van-radio>
						<van-radio name="B">mmol/L</van-radio>
					</van-radio-group>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[8].ve"
					label="甘油三酯(TG)"
					type="number"
					input-align="right"
				>
					<van-radio-group
						v-model="formData.examine[8].ut"
						slot="right-icon"
						icon-size="0.28rem"
						checked-color="#6681FA"
						class="unit"
					>
						<van-radio name="A">mg/dL</van-radio>
						<van-radio name="B">mmol/L</van-radio>
					</van-radio-group>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[9].ve"
					label="谷草转氨酶(GOT/AST)"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">IU/L(U/L)</span>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[10].ve"
					label="谷丙转氨酶(GPT/ALT)"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">IU/L(U/L)</span>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[11].ve"
					label="碱性磷酸酶(ALP)"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">IU/L(U/L)</span>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[12].ve"
					label="白蛋白(ALB)"
					type="number"
					input-align="right"
				>
					<van-radio-group
						v-model="formData.examine[12].ut"
						slot="right-icon"
						icon-size="0.28rem"
						checked-color="#6681FA"
						class="unit"
					>
						<van-radio name="A">g/dL</van-radio>
						<van-radio name="B">g/L</van-radio>
					</van-radio-group>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[13].ve"
					label="肌酐(Cre)"
					type="number"
					input-align="right"
				>
					<van-radio-group
						v-model="formData.examine[13].ut"
						slot="right-icon"
						icon-size="0.28rem"
						checked-color="#6681FA"
						class="unit"
					>
						<van-radio name="A">mg/dL</van-radio>
						<van-radio name="B">umg/dL</van-radio>
					</van-radio-group>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[14].ve"
					label="尿酸(UA)"
					type="number"
					input-align="right"
				>
					<van-radio-group
						v-model="formData.examine[14].ut"
						slot="right-icon"
						icon-size="0.28rem"
						checked-color="#6681FA"
						class="unit"
					>
						<van-radio name="A">mg/dL</van-radio>
						<van-radio name="B">umg/dL</van-radio>
					</van-radio-group>
				</van-field>
			</div>
			
			<div class="item">
				<van-field
					v-model="formData.examine[15].ve"
					label="肾小球滤过率(eGFR)"
					type="number"
					input-align="right"
				>
					<span slot="right-icon">ml/min/1.73m</span>
				</van-field>
			</div>
			
			<van-button @click="handleIsEdit" :disabled="!canEdit" class="edit-btn qjc-wid-100 qjc-fts-34 qjc-c-fff qjc-bg-primary">修改个人信息</van-button>
			<p class="tip qjc-fts-24">您的个人信息仅可修改一次，请谨慎修改</p>
			
		</div>
		
		<van-popup
			v-model="isEdit"
			:close-on-click-overlay="false"
			class="has-report"
		>	
			<div class="action_title">操作提示</div>
			<div class="qjc-texta-l">
				您的个人信息仅可修改一次，请谨慎修改
			</div>
			<van-button @click="edit" class="qjc-c-fff qjc-block qjc-ftw-b qjc-bg-primary qjc-fts-32" round>確認</van-button>
			<van-button @click="isEdit = false" class="qjc-c-fff qjc-block qjc-ftw-b qjc-fts-32" round>取消</van-button>
			
			<div @click="isEdit = false" class="close qjc-absolute qjc-round">
				<van-image
					width="0.265rem"
					height="0.265rem"
					:src="require('@/assets/images/close.png')"
				/>
			</div>
		</van-popup>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Field, ActionSheet, Button, RadioGroup, Radio, Toast, Checkbox, CheckboxGroup, Popup, Image } from 'vant'
	Vue.use(NavBar)
	   .use(Field)
	   .use(ActionSheet)
	   .use(Button)
	   .use(RadioGroup)
	   .use(Radio)
	   .use(Toast)
	   .use(Popup)
	   .use(Image)
	   .use(Checkbox)
	   .use(CheckboxGroup);
	   
	import { editQuestionCommit, newQuestionsSubmit } from '@/js/axios'
	
	export default{
		name: 'editQuestion',
		data() {
			return {
				canEdit: false,
				id: '',
				isEdit: false,
				formData: {
					id: '',
					height: '',
					weight: '',
					waist: '',
					// bust: '',
					hip: '',
					fat: '',
					area: '',
					childcnt: '',
					marry: '',
					examine: [
						{"ne":"WBC","ve":"","ut":"A"},
						{"ne":"HGB","ve":"","ut":"B"},
						{"ne":"PLT","ve":"","ut":"A"},
						{"ne":"FG","ve":"","ut":"B"},
						{"ne":"HBA1C","ve":"","ut":"A"},
						{"ne":"CHOL","ve":"","ut":"B"},
						{"ne":"LDLC","ve":"","ut":"A"},
						{"ne":"HDLC","ve":"","ut":"B"},
						{"ne":"TG","ve":"","ut":"B"},
						{"ne":"GOT","ve":"","ut":"A"},
						{"ne":"GPT","ve":"","ut":"A"},
						{"ne":"ALP","ve":"","ut":"A"},
						{"ne":"ALB","ve":"","ut":"B"},
						{"ne":"CRE","ve":"","ut":"B"},
						{"ne":"UA","ve":"","ut":"B"},
						{"ne":"EGFR","ve":"","ut":"A"}
					],
					disease: '',
					oper: [],
					famdis: [],
					drink: '',
					habeat: '',
					habsport: '',
					sleep: '',
					smoke: '',
					vege: '',
					sbp: '',
					dbp: '',
					tb02: '',
				},
				areaList: [
					{name: "中国香港", code: '4401'},
					{name: "中国台湾", code: '0000'},
					{name: "江苏", code: '3200'},
					{name: "上海", code: '3100'},
					{name: "北京", code: '1100'},
					{name: "天津", code: '1200'},
					{name: "浙江", code: '3300'},
					{name: "广东", code: '4400'},
					{name: "辽宁", code: '2100'},
					{name: "山东", code: '3700'},
					{name: "内蒙古", code: '1500'},
					{name: "重庆", code: '5000'},
					{name: "福建", code: '3500'},
					{name: "海南", code: '4600'},
					{name: "湖北", code: '4200'},
					{name: "湖南", code: '4300'},
					{name: "陝西", code: '6100'},
					{name: "河南", code: '4100'},
					{name: "黑龙江", code: '2300'},
					{name: "青海", code: '6300'},
					{name: "云南", code: '5300'},
					{name: "安徽", code: '3400'},
					{name: "四川", code: '5100'},
					{name: "贵州", code: '5200'},
					{name: "甘肃", code: '6200'},
					{name: "江西", code: '3600'},
					{name: "吉林", code: '2200'},
					{name: "山西", code: '1400'},
					{name: "河北", code: '1300'},
					{name: "宁夏", code: '6400'},
					{name: "广西", code: '4500'},
					{name: "新疆", code: '6500'},
					{name: "西藏", code: '5400'},
				],
				
				diseaseList: [
					{"kdis": "K01", "level": "0", "name": "高血压"},
					{"kdis": "K02", "level": "0", "name": "高血脂"},
					{"kdis": "K03", "level": "0", "name": "糖尿病"},
					{"kdis": "K04", "level": "0", "name": "慢性肝病"},
					{"kdis": "K05", "level": "0", "name": "慢性肾脏病"},
					{"kdis": "K06", "level": "0", "name": "癌症"},
					{"kdis": "K07", "level": "0", "name": "肝硬化"},
					{"kdis": "K08", "level": "0", "name": "慢性阻塞性肺炎"},
					{"kdis": "K09", "level": "0", "name": "关节炎"},
					{"kdis": "K10", "level": "0", "name": "心脏病"},
					{"kdis": "K11", "level": "0", "name": "脑中风"},
					{"kdis": "K12", "level": "0", "name": "胃肠溃疡"},
					{"kdis": "K13", "level": "0", "name": "睡眠障碍"},
					
					// 新增
					{"kdis": "K14", "level": "0", "name": "贫血"},
					{"kdis": "K15", "level": "0", "name": "疼痛"},
					{"kdis": "K16", "level": "0", "name": "哮喘"},
					{"kdis": "K17", "level": "0", "name": "痛风"},
					{"kdis": "K18", "level": "0", "name": "痔疮"},
					{"kdis": "K19", "level": "0", "name": "甲状腺疾病"},
				],
				
				tb02: [
					{'ne': 'dis01','expg': 0, 'kdis': 'K01'},
					{'ne': 'dis02','expg': 0, 'kdis': 'K02'},
					{'ne': 'dis03','expg': 0, 'kdis': 'K03'},
					{'ne': 'dis04','expg': 0, 'kdis': 'K07'},
					{'ne': 'dis05','expg': 0, 'kdis': 'K05'},
					{'ne': 'dis06','expg': 0, 'kdis': 'K14'},
					{'ne': 'dis07','expg': 0, 'kdis': 'K15'},
					{'ne': 'dis08','expg': 0, 'kdis': 'K13'},
					{'ne': 'dis09','expg': 0, 'kdis': 'K16'},
					{'ne': 'dis10','expg': 0, 'kdis': 'K11'},
					{'ne': 'dis11','expg': 0, 'kdis': 'K10'},
					{'ne': 'dis12','expg': 0, 'kdis': 'K06'},
					{'ne': 'dis13','expg': 0, 'kdis': 'K08'},
					{'ne': 'dis14','expg': 0, 'kdis': 'K12'},
					{'ne': 'dis15','expg': 0, 'kdis': 'K17'},
					{'ne': 'dis16','expg': 0, 'kdis': 'K09'},
					{'ne': 'dis17','expg': 0, 'kdis': 'K18'},
					{'ne': 'dis18','expg': 0, 'kdis': 'K19'}
				],
				
				areaText: '',
				areaShow: false,
				marryShow: false,
				marryText: '',
				marryList: [
					{ name: '未婚', code: '2' },
					{ name: '已婚', code: '1' },
					{ name: '分居', code: '5' },
					{ name: '丧偶', code: '3' },
				],
				childcntText: '',
				childcntShow: false,
				childcntList: [
					{ name: '没有', code: '0' },
					{ name: '1名', code: '1' },
					{ name: '2名', code: '2' },
					{ name: '3名及以上', code: '3' },
				],
				drinkText: '',
				drinkShow: false,
				drinkList: [
					{ name: '不喝', code: '1' },
					{ name: '天天喝', code: '2' },
					{ name: '经常喝', code: '3' },
					{ name: '偶尔喝', code: '4' },
				],
				smokeText: '',
				smokeShow: false,
				smokeList: [
					{ name: '不吸', code: '1' },
					{ name: '天天吸', code: '2' },
					{ name: '经常吸', code: '3' },
					{ name: '偶尔吸', code: '4' },
				],
				habeatText: '',
				habeatShow: false,
				habeatList: [
					{ name: '定时定量', code: '1' },
					{ name: '定时但不定量', code: '3' },
					{ name: '不定时但定量', code: '4' },
					{ name: '不定时不定量', code: '2' },
				],
				vegeText: '',
				vegeShow: false,
				vegeList: [
					{ name: '不是', code: '1' },
					{ name: '是', code: '2' },
				],
				habsportText: '',
				habsportShow: false,
				habsportList: [
					{ name: '没有或每周少于1小时', code: '1' },
					{ name: '每周运动1～2小时', code: '2' },
					{ name: '每周运动3～4小时', code: '3' },
					{ name: '每周运动5～6小时', code: '4' },
					{ name: '每週运动7小时以上', code: '5' },
				],
				sleepText: '',
				sleepShow: false,
				sleepList: [
					{ name: '没有', code: '1' },
					{ name: '偶尔', code: '4' },
					{ name: '经常', code: '3' },
					{ name: '非常严重', code: '2' },
				],
				
				hasDis: false,
				hasOper: false,
				hasFamdis: false,
				
			}
		},
		watch: {
			id(newVal, oldVal) {
				this.formData.id = newVal;
				
				editQuestionCommit({
					id: this.formData.id,
				}).then(res => {
					if(res.code == 200){
						let data = JSON.parse(window.atob(res.data))
						console.log(data)
					
						Object.assign(this.formData, data.input_data);
						// 赋值
						this.areaText = this.areaList.filter(a => a.code == this.formData.area)[0].name;
						// this.marryText = this.marryList.filter(a => a.code == (this.formData.marry == '4' ? this.formData.marry = '3' : this.formData.marry))[0].name;
						// this.childcntText = this.childcntList.filter(a => a.code == this.formData.childcnt)[0].name;
						this.drinkText = this.drinkList.filter(a => a.code == this.formData.drink)[0].name;
						this.smokeText = this.smokeList.filter(a => a.code == this.formData.smoke)[0].name;
						this.habeatText = this.habeatList.filter(a => a.code == this.formData.habeat)[0].name;
						this.vegeText = this.vegeList.filter(a => a.code == this.formData.vege)[0].name;
						this.habsportText = this.habsportList.filter(a => a.code == this.formData.habsport)[0].name;
						this.sleepText = this.sleepList.filter(a => a.code == this.formData.sleep)[0].name;
						
						for(let i=0;i<this.formData.disease.length;i++){
							if(this.formData.disease[i].level != '0'){
								this.hasDis = true;
								break;
							}
						}
						let oper = this.formData.oper;
						let famdis = this.formData.famdis;
						
						this.formData.oper = (oper == '' ? [] : oper)
						this.formData.famdis = (famdis == '' ? [] : famdis)
						this.formData.oper.length > 0?(this.hasOper = true):'';
						this.formData.famdis.length > 0?(this.hasFamdis = true):'';
						
						
						this.formData.package = res.data.package
						this.formData.custom = res.data.custom
						
						
						
					}else{
						Toast.fail(res.msg);
						this.$router.replace('/user')
					}
				});
			}
			
		},
		mounted() {
			let id = this.$route.params.id
			this.id = id
			this.canEdit = this.$route.query.isedit>0?false:true;
		},
		methods: {
			handleIsEdit() {
				this.isEdit = true;
			},
			edit() {
				// 写入tb02
				for(let i=0;i<this.formData.disease.length;i++){
					for(let t=0;t<this.tb02.length;t++){
						if(this.tb02[t].kdis == this.formData.disease[i].kdis){
							if(this.formData.disease[i].level == '0'){
								this.tb02[t].expg = 0;
							}else{
								this.tb02[t].expg = 1;
							}
							break;
						}
					}
				}
				this.formData.tb02 = this.tb02;
				
				newQuestionsSubmit(this.formData).then(res => {
					// console.log(res)
					if(res.status == 1){
						Toast.success('修改成功')
						this.isEdit = false;
						this.$router.push('/user/history');
					}else{
						 Toast.fail(res.msg);
					}
				});
				
			},
			
			areaCheck(item) {
				this.areaText = item.name;
				this.formData.area = item.code;
			},
			
			marryCheck(item) {
				this.marryText = item.name;
				this.formData.marry = item.code;
			},
			
			childcntCheck(item) {
				this.childcntText = item.name;
				this.formData.childcnt = item.code;
			},
			
			drinkCheck(item) {
				this.drinkText = item.name;
				this.formData.drink  = item.code;
			},
			
			smokeCheck(item) {
				this.smokeText = item.name;
				this.formData.smoke  = item.code;
			},
			
			habeatCheck(item) {
				this.habeatText = item.name;
				this.formData.habeat  = item.code;
			},
			
			vegeCheck(item) {
				this.vegeText = item.name;
				this.formData.vege  = item.code;
			},
			
			habsportCheck(item) {
				this.habsportText = item.name;
				this.formData.habsport  = item.code;
			},
			
			sleepCheck(item) {
				this.sleepText = item.name;
				this.formData.sleep  = item.code;
			},
			
			
			checkdis(name) {
				if(!name){// 无疾病时清空
					this.formData.disease.forEach(item => {
						item.level = '0';
					})
				}
			},
			
			checkOper(name) {
				if(!name){// 清空
					this.formData.oper = [];
				}
			},
			
			checkFamdis(name) {
				if(!name){// 清空
					this.formData.famdis = [];
				}
			},
		}
	}
</script>

<style scoped>
	.content >>> .van-field__label{
		min-width: 90px;
		width: auto;
		text-align: left;
	}
	.content >>> .van-radio__label,
	.content >>> .van-checkbox__label{
		margin-left: 0.08rem;
	}
	
	.content >>> .van-field__right-icon {
		min-height: 0.8rem;
	}
	
	.content >>> .van-radio-group {
		min-height: 0.8rem;
	}
	
	.content >>> .van-radio__icon {
		/* line-height: 1.4em; */
	}
	
	
</style>
<style lang="scss" scoped>
	.edit{
		background-color: #fff;
		.qjc-nav-bar{
			border-bottom: none;
		}
		
		.content{
			padding: 0.24rem;
			
			h3{
				margin-bottom: 0.32rem;
			}
			.item{
				border: 0.01rem solid rgba(119, 127, 143, .2);
				border-radius: 0.08rem;
				margin-bottom: 0.16rem;
				
				.van-field{
					padding: 0 0.25rem;
					font-size: 0.28rem;
					line-height: 0.8rem;
					background-color: transparent;
				}
				
				.van-radio-group,
				.van-checkbox-group{
					display: flex;
					padding: 0.12rem 0.24rem;
					font-size: 0.24rem;
					
					&.unit{
						padding-right: 0;
						padding-left: 0;
					}
				}
				
				.van-radio-group{
					&.has{
						.van-radio{
							margin: 0 1rem 0 0;
						}
					}
					label{
						min-width: 1.8rem;
						text-align: left;
					}
					.van-radio{
						margin-left: 0.16rem;
					}
				}
				.van-checkbox-group{
					justify-content: space-between;
					flex-wrap: wrap;
					padding-bottom: 0;
					
					.van-checkbox{
						margin-bottom: 0.12rem;
						margin-right: 0.16rem;
					}
				}
			}
			.edit-btn{
				height: 0.88rem;
				line-height: 0.88rem;
				border-radius: 0.08rem;
				margin-top: 0.24rem;
			}
			.tip{
				color: #777F8F;
				margin-top: 0.16rem;
			}
		}
	}
	
	.has-report{
		width: 5.78rem;
		padding: 0.4rem;
		border-radius: 0.08rem;
		overflow: visible;
		
		&>div.qjc-texta-l{
			color: #777F8F;
			font-size: 0.26rem;
			line-height: 0.4rem;
			font-weight: 400;
			margin: 0.32rem 0 0.4rem;
		}
		.action_title {
			font-size: 0.30rem;
			line-height: 0.4rem;
			font-weight: 400;
		}
		.van-button{
			width: 100%;
			height: 0.88rem;
			line-height: 0.88rem;
			border-radius: 0.08rem;
		}
		.close{
			width: 0.72rem;
			height: 0.72rem;
			padding-top: 0.21rem;
			left: 50%;
			bottom: -1.5rem;
			margin-left: -0.36rem;
			border: 0.01rem solid #fff;
		}
	}
</style>
